@font-face {
    font-family: 'Switzer';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Switzer-Regular.woff') format('woff');
}


@font-face {
    font-family: 'Switzer-Thin';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/Switzer-Thin.woff') format('woff');
}






@font-face {
    font-family: 'Switzer-Medium';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Switzer-Medium.woff') format('woff');
}



@font-face {
    font-family: 'Switzer-SemiBold';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/Switzer-Semibold.woff') format('woff');
}



@font-face {
    font-family: 'Switzer-Bold';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Switzer-Bold.woff') format('woff');
}



@font-face {
    font-family: 'Switzer-ExtraBold';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/Switzer-Extrabold.woff') format('woff');
}

@font-face {
    font-family: 'Switzer-Light';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/Switzer-Light.woff') format('woff');
}



@font-face {
    font-family: 'Switzer-Black';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/Switzer-Black.woff') format('woff');
}



body {
    font-family: Switzer !important;
    margin: 0 !important;
}

.footer {
    height: 42px;
    background: #306123;
    flex-direction: row;
    flex: 1 1 0%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: '100% !important';
}

.header {
    height: 90px;
}

.headerBrieziLogo {
    display: flex;
    align-items: center;
    margin-top: 14px;
}

.otpInputField {
    /* border-bottom: 1px solid black !important; */
    font-size: 14px;
    letter-spacing: 2px;
    color: black;
    justify-content: center;
    width: 2em !important;
    text-align: center;
    height: 30px;
    margin-right: 30px;
    margin-top: 24px;
}

.title {
    align-items: center;
    text-align: center;
    margin-top: 24px;
    /* margin-bottom: -24px; */
    font-family: 'Switzer-Regular !important';
    padding-top: 48px;
}

.subTitles {
    font-size: 12px !important;
}

.body {
    font-size: 16px;
}

.semiBoldFont {
    font-family: Switzer-SemiBold !important;
}

.mediumFont {
    font-family: Switzer-Medium !important;
}

.ten {
    font-size: 10px;
}

.lightFont {
    font-family: Switzer-Light !important;
}

.bounced {
    color: #FF4D4F;
}

.bouncedBg {
    background: #ffeaea;
}

.paidBg {
    background: #EDF6EB;
}

.pendingBg {
    background: #FAFAFA;
}

.paid {
    color: #489135;
}

.h2 {
    font-size: 24px;
    font-family: Switzer-Medium;
}

.h3 {
    font-size: 20px;
    font-family: Switzer-Medium;
}

.h5 {
    font-size: 14px;
    font-family: Switzer-Medium;
}

.h4 {
    font-size: 16px;
    font-family: Switzer-Medium;
}

.h1 {
    font-size: 32px !important;
}

.InsurType {
    display: flex;
}

.uploadDiv {
    flex: 0.6;
    display: flex;
    align-items: center;
}

.activeTab {
    background: #4DA235;
    color: #fff
}

.inactiveTab {
    background: #EDF6EB;
}

.inactiveTab1 {
    background: #fff;
}

.inactiveText {
    color: #000;
}

.activeText {
    color: #fff;
}

.cursor {
    cursor: pointer
}

.rowClass {
    display: flex;
    align-items: center;
}

.justifyBetween {
    justify-content: space-between;
}

.statusApprovedBg {
    background: #EDF6EB;
}

.statusRejectBg {
    background: #ffeaea;
}

.rowClassDynamic {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cardLogo {
    display: block;
}

.selectTenure {
    padding: 7px 24px;
}

.camDiv {
    display: flex;
    justify-content: center;
}

.detailsRmPd {
    margin: 24px -24px !important
}

@supports (-webkit-touch-callout: none) {
    @media only screen and (max-width:380px) {
        .otpInputField {
            font-size: 12px;
            margin-right: 10px;
        }
    }
}


@media only screen and (max-width: 600px) {
    .h3 {
        font-size: 12px;
        font-family: Switzer-Medium;
    }

    .cardLogo {
        display: none;
    }

    .rowClassDynamic {
        display: block;
        align-items: center;
    }

    .header {
        background: #fff;
    }

    .selectTenure {
        padding: 7px 12px;
    }

    .h4,
    .body {
        font-size: 12px;
        font-family: Switzer-Medium;
    }

    .otpInputField {
        margin-right: 15px;
    }

    .title {
        margin-bottom: 24px;
    }

    .InsurType {
        display: grid;
    }

    .camDiv {
        display: block;
        text-align: center;
    }

    .hidephone {
        display: none;
    }

    .detailsRmPd {
        margin: 12px -12px !important
    }
}